<!--
 * @Descripttion: 用户装备列表
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-29 00:39:58
 * @LastEditTime: 2021-10-10 01:57:40
-->
<template>
  <div>
    <el-button class="filter-item"
               style="margin:10px"
               type="primary"
               @click="add()">添加装备</el-button>

    <div class="flex"
         style="min-height:630px">

      <el-table v-loading="loading"
                :data="EquipList"
                :stripe="true"
                border>
        <el-table-column prop="equip_id"
                         label="编号"
                         width="60" />
        <el-table-column prop="title"
                         label="主装备"
                         width="150">
          <template slot-scope="scope">
            <span style="border: 1px solid #ff0000;color: red;border-radius: 3px;padding: 1px;"
                  v-if="scope.row.is_hot==1">急售</span>
            {{scope.row.title}}
          </template>
        </el-table-column>
        <el-table-column prop="qufu"
                         label="所在区服"> </el-table-column>
        <el-table-column label="职业"
                         min-width="90">
          <template slot-scope="{row}">
            {{row.zhongzu}}-{{row.zhiye}}
          </template>
        </el-table-column>
        <el-table-column prop="zb_class"
                         label="装备分类" />

        <el-table-column prop="price"
                         min-width="100"
                         label="价格(元)">
          <template slot-scope="scope">
            <span v-if="scope.row.price < 10000"
                  style="color: black">{{
            scope.row.price | money
          }}</span>
            <span v-else-if="scope.row.price > 1000 && scope.row.price < 100000"
                  style="color: orange">{{ scope.row.price | money }}</span>
            <span v-else
                  style="color: red">{{ scope.row.price | money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         width="80"
                         label="状态"
                         sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0"
                  style="color: orange">
              待审核
            </span>
            <span v-else-if="scope.row.status == 1"
                  style="color: green">
              审核通过
            </span>
            <span v-else-if="scope.row.status == 2"> 已售出 </span>
            <span v-else-if="scope.row.status == 3"
                  style="color: red">
              拒绝通过
              <div style="border: 1px solid #f7a8a8;
    color: #ffa500;">
                {{scope.row.reject_info}}
              </div>
            </span>

          </template>
        </el-table-column>
        <el-table-column label="操作"
                         min-width="90"
                         align="center"
                         fixed="right">
          <template slot-scope="{ row }">

            <el-button v-if="row.status != 2"
                       type="text"
                       size="small"
                       @click="edit(row)">修改</el-button>
            <el-button type="text"
                       size="small"
                       @click="deleEquip(row)"
                       v-if="row.status !=2 ">删除</el-button>
            <el-button v-if="row.status == 2"
                       type="text"
                       size="small">已售出</el-button>
          </template>
        </el-table-column>

      </el-table>

    </div>
    <!-- 分页 -->
    <pagination v-show="count > 0"
                :total="count"
                :page.sync="query.page"
                :limit.sync="query.limit"
                @pagination="getEquipList"
                style="padding:10px" />

    <!-- 添加、修改 -->
    <el-dialog class="dialog"
               :visible.sync="editDialog"
               :modal-append-to-body=false
               top="1vh">
      <el-form ref="ref"
               :rules="rules"
               :model="model"
               class="dialog-body"
               label-width="100px">

        <el-form-item label="游戏区服"
                      prop="qf_cid"
                      clearable
                      placeholder="请选择游戏区服">
          <el-cascader v-model="model.qf_cid"
                       :options="qufu"
                       :props="{ value: 'category_id', label: 'category_name' }"
                       style="width: 100%"
                       clearable
                       filterable
                       placeholder="请选择游戏区服"
                       @change="QuFuChange" />
        </el-form-item>
        <el-form-item label="装备分类"
                      prop="zb_cid"
                      clearable
                      placeholder="请选择装备分类">
          <el-select class="filter-item"
                     v-model="model.zb_cid"
                     clearable
                     style="width: 100%"
                     @change="ZhuangBeiChange"
                     placeholder="请选择装备分类">
            <el-option v-for="item in zb_class"
                       :key="item.category_id"
                       :label="item.category_name"
                       :value="item.category_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="种族职业"
                      prop="zy_cid"
                      clearable
                      placeholder="请选择种族职业">
          <el-cascader v-model="model.zy_cid"
                       :options="zhiye"
                       :props="{ value: 'category_id', label: 'category_name' }"
                       style="width: 100%"
                       placeholder="请选择种族职业"
                       @change="ZhiYeChange"></el-cascader>
        </el-form-item>

        <el-form-item label="装备描述"
                      prop="title">
          <el-input v-model="model.title"
                    type="text"
                    clearable
                    placeholder="请输入装备描述" />
        </el-form-item>
          <el-form-item v-if="false" label="联系人"
                      prop="contacts">
          <el-input v-model="model.contacts"
                    type="text"
                    clearable
                    placeholder="请输入联系人名称，例如：黄先生" />
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="title">
          <el-input v-model="model.contact"
                    type="text"
                    clearable
                    placeholder="请输入联系方式" />
        </el-form-item>

        <el-form-item label="价格"
                      prop="price">
          <el-input v-model="model.price"
                    type="number"
                    clearable
                    placeholder="请输入出售价格，单位（元）" />
        </el-form-item>

        <el-form-item v-if="false" label="详细图文"
                      clearable
                      prop="content">
          <vue-ueditor-wrap v-model="model.content"
                            :config="{
              initialFrameHeight: 240,
              initialFrameWidth: '100%',
              UEDITOR_HOME_URL:'/static/ueditor/',
              zIndex: 999999,
            }"></vue-ueditor-wrap>
        </el-form-item>
        <el-form-item label="是否急售"
                      prop="is_hot">
          <el-radio-group disabled
                          v-model="model.is_hot">
            <el-radio border
                      :label=1>急售</el-radio>
            <el-radio border
                      :label=0>非急售</el-radio>
          </el-radio-group>

          <el-tag style="margin-left: 1rem"
                  type="danger">温馨提示：急售请联系站长</el-tag>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary"
                   @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCateGory } from "@/api";
import { getMembertEquipList, addEquip,  editEquip,getEquiInfo,deleEquip } from "@/api/equip";
import Pagination from "@/components/Pagination";
import VueUeditorWrap from "vue-ueditor-wrap";
import { MessageBox } from 'mint-ui';
export default {
  name: "DbgamewebMemberequip",

  components: { Pagination, VueUeditorWrap },

  directives: {},

  data() {
    return {
      model: {
        equip_id: "",
        content: "",
        // contacts:"",
        zz_cid: "",
        qf_cid: "",
        zy_cid: "",
        zb_cid: "",
        title: "",
        is_hot: 0,
        price: "",
        status: 0,
      },
      query: {
        //查询分页
        page: 1,
        limit: 12,
      },
      rules: {
        qf_cid: [
          { required: true, message: "请选择游戏区服", trigger: "blur" },
        ],
        zy_cid: [
          { required: true, message: "请选择种族职业", trigger: "blur" },
        ],
        zb_cid: [
          { required: true, message: "请选择装备类型", trigger: "blur" },
        ],
        title: [
          {
            required: true,
            message: "请输入装备描述",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入出售价格，单位（元）",
            trigger: "blur",
          },
        ],
        // contacts: [
        //   { required: true, message: "请输入联系人", trigger: "blur" },
        // ],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
      },
      EquipList: [],
      loading: false,
      count: 0,
      qufu: [],
      zhiye: [],
      zb_class: [],
      editDialog: false,
    };
  },

  mounted() {
         if (this.$store.state.user.memberInfo) {
      // let memberInfo =this.$store.state.user.memberInfo;
      let memberInfo = JSON.parse(this.$store.state.user.memberInfo);
      this.memberInfo = memberInfo;
      this.uid = memberInfo.member_id;
    } else {

         this.$message.warning('请先登录！');
      this.$router.push({ path: "/login" });
    }
    this.getCateGory();
    this.getEquipList();
  },

  methods: {
         //删除
    deleEquip(row) {
     // console.log(row);
      var title = " 删除-编号：" + row.equip_id;
      var message =
        '确定要彻底删除<span style="color:red">' + row.title + " </span>吗？";


      if(this._isMobole()){
  
        MessageBox.confirm(message, title).then(() => {
 this.loading = true;
          deleEquip({
            equip_id: row.equip_id,
          })
            .then((res) => {
              // console.log('成功')
              this.loading = false;
              this.getEquipList();
              this.reset();
              this.$message.success(res.msg);
            })
            .catch(() => {
              this.loading = false;
            });
}).catch(() => {});
      }else{
        this.$confirm(message, title, {
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.loading = true;
          deleEquip({
            equip_id: row.equip_id,
          })
            .then((res) => {
              // console.log('成功')
              this.loading = false;
              this.getEquipList();
              this.reset();
              this.$message.success(res.msg);
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
      }

     
    },
      // 修改
    edit(row) {
      this.model.content = "";
      this.editDialog = true;
      this.dialogTitle = "修改"; // + row.game_role_id;
      getEquiInfo({
        equip_id: row.equip_id,
      })
        .then((res) => {
          let row = res.data;
        //   delete row["ZH_Type"]
        //   delete row["zhiye"]
        //   delete row["zhongzu"]
        //   delete row["qufu"]
        //   delete row["zy_pid"]
        //   delete row["favorite"]
          //           console.log(row.indexOf('ZH_Type'));
          //           console.log(row.indexOf('zhiye'));
          // console.log(row.indexOf('zhongzu'));

          this.reset(row);
      //    this.loading = false;
        })
        .catch(() => {
        //  this.loading = false;
        });
      // this.category()
    },
    // 重置
    reset(row) {
      if (row) {
        this.model = row;
        if (this.model.equip_id === 0) {
          this.model.equip_id = "";
        }
      } else {
        this.model = this.$options.data().model;
      }
      if (this.$refs["ref"] !== undefined) {
        this.$refs["ref"].resetFields();
        this.$refs["ref"].clearValidate();
      }
    },
    ZhiYeChange(value) {
      if (value) {
        this.model.zy_cid = value[value.length - 1];
      }
    },
    ZhuangBeiChange(value) {
      if (value) {
        this.model.zb_cid = value;
      }
    },
    getEquipList() {
      this.editDialog = false;
      let params = {
        qf_cid: this.$route.params.id,
        zy_cid: this.query.zy_cid,
        zb_cid: this.query.zb_cid,
        page: this.query.page,
        limit: this.query.limit,
      };

      getMembertEquipList(params).then((res) => {
        //console.log(res.data.list);
        if (res.data.list.length != 0) {
          this.EquipList = res.data.list;
          this.count = res.data.count;
          //(this.EquipList);
          this.loding = true;
        } else {
          // console.log('没数据了')
          this.EquipList = res.data.list;
        }
      });
    },
    //提交
    submit() {
      this.$refs["ref"].validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.model.equip_id) {
            editEquip(this.model)
              .then((res) => {
                this.editDialog = false;
                   this.loading = false;
                this.getEquipList();
                this.reset();
                this.$message.success(res.msg);
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            addEquip(this.model)
              .then((res) => {
                  //(res.msg)
                this.editDialog = false;
                   this.loading = false;
                this.getEquipList();
                this.reset();
                this.$message.success(res.msg);
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    // 取消
    cancel() {
      this.reset();
      this.editDialog = false;
    },
    // 父级选择
    QuFuChange(value) {
      if (value) {
        this.model.qf_cid = value[value.length - 1];
      }
    },
    //获取分类列表
    getCateGory() {
      //装备分类
      var data = { pid: 97 };
      getCateGory(data)
        .then((res) => {
          this.zb_class = res.data.clist;
        })
        .catch(() => {});
      //区服分类
      data = { pid: 23 };
      getCateGory(data)
        .then((res) => {
          this.qufu = res.data.clist;
        })
        .catch(() => {});
      //职业分类
      data = { pid: 24 };
      getCateGory(data)
        .then((res) => {
          //res.data.clist.pop(); //移除最后一个数组，全职业
          this.zhiye = res.data.clist;
        })
        .catch(() => {});
    },
    // 添加
    add() {
      this.editDialog = true;
      this.dialogTitle = "添加";
      this.model = this.$options.data().model;
      //this.category()
    },
     _isMobole(){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
    },
  },
  
  filters: {
    //钩子，过滤器
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ".00"; //预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>